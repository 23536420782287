import Button, {Props as ButtonProps} from './Button';
import {ButtonSize} from './options';
import {NEXT_URL_SEARCH_PARAM_KEY} from '../../../hooks/useNavigateToNextUrl';
import {CSSStyle} from '../../../types/CSSStyles';
import appendNonEmptySearchParams from '../../../url/appendNonEmptySearchParams';
import {useGlobalNavigationQuery} from '../../layout/__generated__/GlobalNavigation.graphql';

import React from 'react';

type ButtonPropKey = 'color' | 'size';
export interface Props extends Pick<ButtonProps, ButtonPropKey> {
  readonly guestLabel: string;
  readonly guestTo: string;
  readonly loggedInLabel: string;
  readonly loggedInTo: string;
  readonly next?: string;
  readonly style?: CSSStyle;
}

export default function GuestOrLoggedInCTAButton(
  props: Props,
): JSX.Element | null {
  const {
    guestLabel,
    guestTo,
    loggedInLabel,
    loggedInTo,
    next,
    style,
    ...buttonProps
  } = props;
  const {data} = useGlobalNavigationQuery({
    fetchPolicy: 'cache-only',
  });
  const isGuest = data?.viewer?.id == null;

  let path;
  if (isGuest) {
    path = appendNonEmptySearchParams(guestTo, {
      [NEXT_URL_SEARCH_PARAM_KEY]: next,
    });
  } else {
    path = loggedInTo;
  }

  return (
    <Button
      label={isGuest ? guestLabel : loggedInLabel}
      size={ButtonSize.LARGE}
      sx={style}
      to={path}
      {...buttonProps}
    />
  );
}
