import MarketingTopNavItem, {Placement} from './MarketingTopNavItem';
import {Options as NavLinkProperty} from './useNavLinkProperty';
import {linkToMarketing} from '../../../url/linkToMarketing';
import {ContentColor} from '../../design_system/theme/useContentPalette';

import React from 'react';
import {useTranslation} from 'react-i18next';

export interface Props {
  readonly navColor?: NavLinkProperty<ContentColor>;
  readonly placement?: Placement;
}

export default function GenericNavControls(props: Props = {}) {
  const {navColor, placement = 'header'} = props;
  const {t} = useTranslation();
  return [
    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="about"
      matchColor={navColor?.matchedValue}
      placement={placement}
      to={linkToMarketing('/about')}
    >
      {t('pages.about.about', {brand: t('common.brand')})}
    </MarketingTopNavItem>,

    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="event"
      matchColor={navColor?.matchedValue}
      matcher="/event/:id?"
      placement={placement}
      to={linkToMarketing('/event')}
    >
      {t('nav.events')}
    </MarketingTopNavItem>,

    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="community"
      matchColor={navColor?.matchedValue}
      placement={placement}
      to={linkToMarketing('/community')}
    >
      {t('pages.community.our-community')}
    </MarketingTopNavItem>,

    <MarketingTopNavItem
      idleColor={navColor?.idleValue}
      key="faq"
      matchColor={navColor?.matchedValue}
      placement={placement}
      to={linkToMarketing('/faq')}
    >
      {t('pages.faq.faq')}
    </MarketingTopNavItem>,
  ];
}
