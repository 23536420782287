import GuestOrLoggedInCTAButton, {
  Props as ButtonProps,
} from './GuestOrLoggedInCTAButton';
import {linkToApp} from '../../../url/linkToApp';
import {useGlobalNavigationQuery} from '../../layout/__generated__/GlobalNavigation.graphql';

import React from 'react';
import {useTranslation} from 'react-i18next';

type OmitKey = 'guestTo' | 'loggedInTo' | 'guestLabel' | 'loggedInLabel';

export interface Props extends Omit<ButtonProps, OmitKey> {
  readonly guestLabel?: string;
  readonly loggedInLabel?: string;
  readonly next?: string;
}

export default function SignupCTAButton(props: Props): JSX.Element | null {
  const {t} = useTranslation();

  const {
    guestLabel = t('nav.signup'),
    loggedInLabel = t('nav.signup'),
    next,
    ...buttonProps
  } = props;
  const {data} = useGlobalNavigationQuery({
    fetchPolicy: 'cache-only',
  });

  if (!data?.featureFlags.signup) {
    return null;
  }

  return (
    <GuestOrLoggedInCTAButton
      guestLabel={guestLabel}
      guestTo={linkToApp('/signup')}
      loggedInLabel={loggedInLabel}
      loggedInTo={linkToApp('/signup')}
      next={next}
      {...buttonProps}
    />
  );
}
