import {AppIconProps} from '../../../design_system/icon/AppIconProps';
import useFeatherIconProps from '../../../design_system/icon/useFeatherIconProps';

import React from 'react';

export default function FooterInstagramIcon(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <rect
        fill="#6A4956"
        height="40"
        rx="20"
        width="40"
      />
      <path
        d="M25 10H15C12.2386 10 10 12.2386 10 15V25C10 27.7614 12.2386 30 15 30H25C27.7614 30 30 27.7614 30 25V15C30 12.2386 27.7614 10 25 10Z"
        stroke="#C7BBC0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M24 19.3701C24.1234 20.2023 23.9812 21.0523 23.5937 21.7991C23.2062 22.5459 22.5931 23.1515 21.8416 23.5297C21.0901 23.908 20.2384 24.0397 19.4077 23.906C18.5771 23.7723 17.8097 23.3801 17.2148 22.7852C16.6199 22.1903 16.2277 21.4229 16.094 20.5923C15.9604 19.7616 16.092 18.91 16.4703 18.1584C16.8485 17.4069 17.4541 16.7938 18.2009 16.4063C18.9477 16.0188 19.7977 15.8766 20.63 16.0001C21.4789 16.1259 22.2648 16.5215 22.8716 17.1284C23.4785 17.7352 23.8741 18.5211 24 19.3701Z"
        stroke="#C7BBC0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
      <path
        d="M25.5 14.5H25.51"
        stroke="#C7BBC0"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
      />
    </svg>
  );
}
