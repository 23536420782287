import {CSSStyles} from '../../types/CSSStyles';
import useAppTheme from '../design_system/hooks/useAppTheme';
import useMediaQuery from '../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../design_system/hooks/useMediaQueryGetter';

import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly children: React.ReactNode;
  readonly hero?: React.ReactNode;
  readonly style?: CSSStyles;
}

const FULL_WIDTH = css({
  boxSizing: 'border-box',
  width: '100%',
});

export default function PageContainer(props: Props): JSX.Element {
  const {children, hero, style} = props;
  const {sizes, spacing} = useAppTheme();

  return (
    <div
      css={[
        css({
          paddingBottom: spacing.x80,
        }),
        FULL_WIDTH,
        style,
      ]}
    >
      <div css={FULL_WIDTH}>{hero}</div>
      <div
        css={[
          css({
            boxSizing: 'border-box',
            paddingLeft: sizes.l.pageSidePadding,
            paddingRight: sizes.l.pageSidePadding,

            [useMediaQuery(MediaQuery.SmallScreen)]: {
              paddingLeft: sizes.sm.pageSidePadding,
              paddingRight: sizes.sm.pageSidePadding,
            },
            [useMediaQuery(MediaQuery.MediumScreen)]: {
              paddingLeft: sizes.md.pageSidePadding,
              paddingRight: sizes.md.pageSidePadding,
            },
          }),
          FULL_WIDTH,
        ]}
      >
        <div
          css={[
            css({
              paddingLeft: sizes.l.pageContentSidePadding,
              paddingRight: sizes.l.pageContentSidePadding,

              [useMediaQuery(MediaQuery.SmallScreen)]: {
                paddingLeft: sizes.sm.pageContentSidePadding,
                paddingRight: sizes.sm.pageContentSidePadding,
              },
              [useMediaQuery(MediaQuery.MediumScreen)]: {
                paddingLeft: sizes.md.pageContentSidePadding,
                paddingRight: sizes.md.pageContentSidePadding,
              },
            }),
            FULL_WIDTH,
          ]}
        >
          <div css={FULL_WIDTH}>{children}</div>
        </div>
      </div>
    </div>
  );
}
