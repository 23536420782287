import {AppIconProps} from '../../../design_system/icon/AppIconProps';
import useFeatherIconProps from '../../../design_system/icon/useFeatherIconProps';

import React from 'react';

export default function FooterLinkedIcon(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <rect
        fill="#6A4956"
        height="40"
        rx="20"
        width="40"
      />
      <g clipPath="url(#clip0_1688_6169)">
        <path
          d="M15.6555 15.4121H11.6405C11.4623 15.4121 11.3179 15.5566 11.3179 15.7347V28.6333C11.3179 28.8115 11.4623 28.9558 11.6405 28.9558H15.6555C15.8337 28.9558 15.9781 28.8115 15.9781 28.6333V15.7347C15.9781 15.5566 15.8337 15.4121 15.6555 15.4121Z"
          fill="#C7BBC0"
        />
        <path
          d="M13.6494 9C12.1885 9 11 10.1872 11 11.6465C11 13.1065 12.1885 14.2941 13.6494 14.2941C15.1092 14.2941 16.2967 13.1064 16.2967 11.6465C16.2968 10.1872 15.1092 9 13.6494 9Z"
          fill="#C7BBC0"
        />
        <path
          d="M25.868 15.0916C24.2555 15.0916 23.0634 15.7848 22.3404 16.5725V15.7347C22.3404 15.5566 22.196 15.4121 22.0178 15.4121H18.1727C17.9945 15.4121 17.8501 15.5566 17.8501 15.7347V28.6333C17.8501 28.8115 17.9945 28.9559 18.1727 28.9559H22.1789C22.3571 28.9559 22.5015 28.8115 22.5015 28.6333V22.2515C22.5015 20.101 23.0856 19.2632 24.5847 19.2632C26.2174 19.2632 26.3471 20.6063 26.3471 22.3621V28.6334C26.3471 28.8116 26.4915 28.9559 26.6697 28.9559H30.6775C30.8556 28.9559 31 28.8116 31 28.6334V21.5583C31 18.3605 30.3903 15.0916 25.868 15.0916Z"
          fill="#C7BBC0"
        />
      </g>
      <defs>
        <clipPath id="clip0_1688_6169">
          <rect
            fill="white"
            height="20"
            transform="translate(11 9)"
            width="20"
          />
        </clipPath>
      </defs>
    </svg>
  );
}
