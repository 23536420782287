import EndPattern from './EndPattern';
import FooterInstagramIcon from './icons/FooterInstagramIcon';
import FooterLinkedIcon from './icons/FooterLinkedinIcon';
import FooterNoteIcon from './icons/FooterNoteIcon';
import FooterTwitterIcon from './icons/FooterTwitterIcon';
import StartPattern from './StartPattern';
import {CSSStyle} from '../../../types/CSSStyles';
import {
  linkToAllizInstagram,
  linkToAllizLinkedin,
  linkToAllizNote,
  linkToAllizX,
  linkToMarketing,
} from '../../../url/linkToMarketing';
import IconButton from '../../design_system/Button/IconButton';
import SignupCTAButton from '../../design_system/Button/SignupCTAButton';
import useAppTheme from '../../design_system/hooks/useAppTheme';
import useMediaQuery from '../../design_system/hooks/useMediaQuery';
import {MediaQuery} from '../../design_system/hooks/useMediaQueryGetter';
import {ContentColor} from '../../design_system/theme/useContentPalette';
import HeadingText from '../../design_system/typography/HeadingText';
import Flex from '../flex/Flex';
import Brand from '../Nav/Brand';
import GenericNavControls from '../Nav/GenericNavControls';
import NavLink from '../Nav/NavLink';
import {Options} from '../Nav/useNavLinkProperty';
import PageContainer from '../PageContainer';

import {css} from '@emotion/react';
import React from 'react';
import {Trans, useTranslation} from 'react-i18next';

const SOCIAL_BUTTON_STYLE: CSSStyle = css({
  borderRadius: '50%',
  height: 40,
  width: 40,
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  padding: 0,
});
const SOCIAL_ICON_SIZE = 20;

export interface Props {}

export default function InfoFooter(_: Props): JSX.Element {
  const {palettes, sizes, spacing} = useAppTheme();
  const {t} = useTranslation();
  const navColor: Options<ContentColor> = {
    idleValue: ContentColor.PRIMARY_CONTRAST,
    matchedValue: ContentColor.PRIMARY_CONTRAST,
  };

  return (
    <div
      css={css({
        backgroundColor: palettes.background.neutral.default,
        height: 252,
        overflow: 'hidden',
        position: 'relative',
        width: '100%',

        [useMediaQuery(MediaQuery.SmallAndMedium)]: {
          height: 'auto',
        },
      })}
    >
      <PageContainer
        style={css({
          paddingTop: sizes.l.topNavHeight,
          height: '100%',
          width: '100%',
          [useMediaQuery(MediaQuery.SmallScreen)]: {
            paddingTop: sizes.sm.topNavHeight,
          },
          [useMediaQuery(MediaQuery.MediumScreen)]: {
            paddingTop: sizes.md.topNavHeight,
          },
        })}
      >
        <div
          css={css({
            columnGap: spacing.x20,
            display: 'grid',
            gridTemplateColumns: '1fr auto',
            gridTemplateRows: 'auto auto',
            rowGap: spacing.x28,

            [useMediaQuery(MediaQuery.LargeScreen)]: {
              gridTemplateColumns: '1fr 1fr',
            },
            [useMediaQuery(MediaQuery.SmallAndMedium)]: {
              gridTemplateRows: 'auto auto auto',
            },
          })}
        >
          <div
            css={css({
              display: 'grid',
              gap: spacing.x20,
              gridColumn: '1 / span 1',
              gridRow: '1 / span 1',
              gridTemplateColumns: 'repeat(5, min-content)',

              [useMediaQuery(MediaQuery.SmallAndMedium)]: {
                gridTemplateColumns: 'repeat(2, 1fr)',
              },
              [useMediaQuery(MediaQuery.SmallScreen)]: {
                marginTop: 70,
              },
              [useMediaQuery(MediaQuery.MediumScreen)]: {
                gridColumnStart: 2,
              },
            })}
          >
            <GenericNavControls
              navColor={navColor}
              placement="footer"
            />
            <NavLink
              idleColor={navColor.idleValue}
              matchColor={navColor.matchedValue}
              to={linkToMarketing('/contact')}
            >
              {t('pages.contact.contact-page-title')}
            </NavLink>
          </div>

          <Flex
            sx={css({
              gap: spacing.x32,
              gridColumn: '1 / span 1',
              gridRow: '2 / span 1',

              [useMediaQuery(MediaQuery.MediumScreen)]: {
                gridColumnStart: 2,
              },
            })}
          >
            <IconButton
              icon={<FooterInstagramIcon size={SOCIAL_ICON_SIZE} />}
              label={t('common-strings.instagram')}
              sx={SOCIAL_BUTTON_STYLE}
              target="__blank"
              to={linkToAllizInstagram()}
            />
            <IconButton
              icon={<FooterLinkedIcon size={SOCIAL_ICON_SIZE} />}
              label={t('common-strings.linkedin')}
              sx={SOCIAL_BUTTON_STYLE}
              target="__blank"
              to={linkToAllizLinkedin()}
            />
            <IconButton
              icon={<FooterTwitterIcon size={SOCIAL_ICON_SIZE} />}
              label={t('common-strings.twitter')}
              sx={SOCIAL_BUTTON_STYLE}
              target="__blank"
              to={linkToAllizX()}
            />
            <IconButton
              icon={<FooterNoteIcon size={SOCIAL_ICON_SIZE} />}
              label={t('common-strings.ssn-note')}
              sx={SOCIAL_BUTTON_STYLE}
              target="__blank"
              to={linkToAllizNote()}
            />
          </Flex>

          <div
            css={css({
              gridColumn: '2 / span 1',
              gridRow: '1 / span 2',

              [useMediaQuery(MediaQuery.SmallAndMedium)]: {
                borderTop: `2px solid ${palettes.background.neutralSubdued.active}`,
                gridColumn: '1 / span 2',
                gridRow: '3 / span 1',
                paddingTop: spacing.x40,
              },
            })}
          >
            <div
              css={css({
                columnGap: spacing.x20,
                display: 'grid',
                gridTemplateColumns: 'minmax(170px, 200px) auto',
                gridTemplateRows: 'auto auto',
                rowGap: spacing.x16,
                justifyContent: 'center',

                [useMediaQuery(MediaQuery.MediumScreen)]: {
                  gridTemplateColumns: '1fr 1fr',
                  justifyItems: 'center',
                },

                [useMediaQuery(MediaQuery.SmallScreen)]: {
                  gridTemplateColumns: '1fr',
                },
              })}
            >
              <Flex
                sx={css({
                  gridColumn: '1 / span 1',
                  gridRow: '1 / span 1',

                  [useMediaQuery(MediaQuery.SmallScreen)]: {
                    gridColumn: '1 / span 1',
                    gridRow: '2 / span 1',
                    justifyContent: 'center',
                  },
                })}
              >
                <Brand />
              </Flex>
              <Flex
                sx={css({
                  [useMediaQuery(MediaQuery.SmallScreen)]: {
                    justifyContent: 'center',
                  },
                })}
              >
                <HeadingText
                  color={ContentColor.PRIMARY}
                  isDisabled={true}
                  size="SS"
                  sx={css({whiteSpace: 'nowrap'})}
                >
                  <Trans i18nKey="footer.footer-cta-label" />
                </HeadingText>
              </Flex>

              <Flex
                sx={css({
                  gridColumn: '2 / span 1',
                  gridRow: '1 / span 2',

                  [useMediaQuery(MediaQuery.SmallScreen)]: {
                    gridColumn: '1 / span 1',
                    gridRow: '1 / span 1',
                    justifyContent: 'center',
                  },
                })}
              >
                <SignupCTAButton
                  style={css({
                    whiteSpace: 'nowrap',
                    backgroundImage:
                      'linear-gradient(to right, #FF3A8B, #FFBA4A)',
                  })}
                />
              </Flex>
            </div>
          </div>
        </div>
      </PageContainer>

      <div
        css={css({
          alignItems: 'stretch',
          display: 'grid',
          gridTemplateColumns: 'auto 1fr auto',
          height: '100%',
          left: 0,
          pointerEvents: 'none',
          position: 'absolute',
          top: 0,
          width: '100%',

          [useMediaQuery(MediaQuery.SmallAndMedium)]: {
            gridTemplateColumns: 'auto 0 0',
          },
        })}
      >
        <StartPattern
          style={css({
            [useMediaQuery(MediaQuery.SmallScreen)]: {
              transform: 'translateY(-50px)',
            },
          })}
        />
        <div />
        <EndPattern
          style={css({
            alignSelf: 'end',
          })}
        />
      </div>
    </div>
  );
}
