import {CSSStyles} from '../../../types/CSSStyles';
import {css} from '@emotion/react';
import React from 'react';

export interface Props {
  readonly style?: CSSStyles;
}

export default function EndPattern(props: Props): JSX.Element {
  const {style} = props;

  return (
    <div
      css={[
        css({
          transform: 'translateY(3px)',
        }),
        style,
      ]}
    >
      <svg
        width="239"
        height="152"
        viewBox="0 0 239 152"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M5.5693 152.618C16.9368 137.401 33.4264 126.057 51.6507 120.744C71.7056 114.901 92.4664 117.659 112.894 119.506C132.287 121.264 152.811 121.916 170.94 113.641C180.461 109.289 188.593 102.443 195.752 94.9076C203.512 86.7464 210.545 77.8631 217.595 69.0848C232.447 50.5853 246.605 31.5017 259.478 11.5667C261.078 9.08783 262.666 6.58985 264.231 4.08954C265.934 1.35602 261.714 -1.32631 260.012 1.40721C247.41 21.6221 233.469 40.9617 218.738 59.6805C211.714 68.6053 204.601 77.5208 197.1 86.0593C190.218 93.9017 182.682 101.47 173.566 106.695C156.491 116.482 136.066 116.438 117.012 114.872C96.3496 113.172 75.3524 109.873 54.8522 114.758C36.0844 119.191 18.6648 129.613 5.99854 144.17C4.38575 146.036 2.8213 147.956 1.34125 149.93C0.532631 151.016 1.03811 152.7 2.11833 153.387C3.33358 154.159 4.76068 153.704 5.5693 152.618Z"
          fill="url(#paint0_linear_1028_447)"
        />
        <defs>
          <linearGradient
            id="paint0_linear_1028_447"
            x1="61.0278"
            y1="185.017"
            x2="198.24"
            y2="-30.8327"
            gradientUnits="userSpaceOnUse"
          >
            <stop stopColor="#FF3A8B" />
            <stop
              offset="1"
              stopColor="#FFBA4A"
            />
          </linearGradient>
        </defs>
      </svg>
    </div>
  );
}
