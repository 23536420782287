import {AppIconProps} from '../../../design_system/icon/AppIconProps';
import useFeatherIconProps from '../../../design_system/icon/useFeatherIconProps';

import React from 'react';

export default function FooterNoteIcon(props: AppIconProps): JSX.Element {
  const iconProps = useFeatherIconProps(props);
  return (
    <svg
      fill="none"
      height="40"
      viewBox="0 0 40 40"
      width="40"
      xmlns="http://www.w3.org/2000/svg"
      {...iconProps}
    >
      <rect
        fill="#6A4956"
        height="40"
        rx="20"
        width="40"
      />
      <path
        d="M12.7273 11.9178C15.879 11.9178 20.1934 11.7591 23.2916 11.8422C27.4454 11.948 29.0136 13.739 29.0671 18.1522C29.1207 20.6535 29.0671 27.8023 29.0671 27.8023H24.5691C24.5691 21.5452 24.592 20.5099 24.5691 18.5376C24.5155 16.7995 24.0183 15.9758 22.6643 15.8171C21.2338 15.6584 17.2253 15.7944 17.2253 15.7944V27.8099H12.7273V11.9178Z"
        fill="#C7BBC0"
      />
    </svg>
  );
}
